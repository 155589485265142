import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Stack, TextField, IconButton, CircularProgress, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate,useLocation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { exportToCsv } from "../../../utils/utilFunctions";
import { getSalesCallsForCustomer } from "../../../features/sales/actions";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import TitleHeader from "../../../components/TitleHeader";



const SalesCallDetailView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = location.state;
  const {  salesCallsForCustomer } = useSelector((state) => state.sales);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getSalesCallsForCustomer(params));
    setIsDataLoading(false)
  }, [params]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const columns = [
    { field: "category", headerName: "Category", width: 100, flex: 0.1 },
    { field: "visitedDate", headerName: "Visited Date", width: 100, flex: 0.1, sortComparator: dateComparator, },
    { field: "nextMeetingDate", headerName: "Next Meeting", width: 100, flex: 0.08, sortComparator: dateComparator, },
    { field: "opportunityStatus", headerName: "Opportunity Status", width: 100, flex: 0.15 },
    { field: "salesPerson", headerName: "Sales Person", width: 100, flex: 0.08 },
    { field: "timeSpentMinutes", headerName: "Time Spent in Minutes", width: 100, flex: 0.12 },
    { field: "remarks", headerName: "Remarks", width: 150, flex: 0.3 },
  ];

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["category,visitedDate, nextMeetingDate, opportunityStatus,salesPerson,timeSpentMinutes,remarks"];
    const keys = ["category", "visitedDate", "nextMeetingDate","opportunityStatus","salesPerson","timeSpentMinutes","remarks"];
    exportToCsv(headers, keys, salesCallsForCustomer.data, `Sales-call-details-${params.customerName}`);
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);
  const handleClearSearch = () => setSearchTerm("");

  return (
    <div>
        <Stack sx={{ ml: 4, mt: 2,mb:-1 }}>
        <TitleHeader
          titles={[params.customerName]}
        />
      </Stack>
       {/* <Typography sx={{paddingLeft:5,paddingTop:3}}>Customer Name:{params.customerName}</Typography> */}
      <Stack sx={{ mt: 4, mx: 4 }} direction={"row"} justifyContent={"space-between"}>
        <TextField
          style={{ minWidth: 300, boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm ? (
                  <IconButton edge="end" onClick={handleClearSearch} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Stack direction={"row"} gap={2}>
          {/* <Button
            variant="contained"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
            onClick={downloadCsv}
            // disabled={allCustomersByState.customers.length ? false : true}
          >
            Export to CSV
          </Button> */}
        </Stack>
      </Stack>
      <Box sx={{ height: 600, px: 3, mt: 3,ml:1, }}>
        {isDataLoading ? (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <DataGrid
            rows={salesCallsForCustomer.data?.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase())) || []}
            columns={columns}
            pageSize={5}
            localeText={{ noRowsLabel: "No Data." }}
          />
        )}
      </Box>
    </div>
  );
};

export default SalesCallDetailView;
