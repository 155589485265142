import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import api from "../../app/api";

export const readCustomersByState = createAsyncThunk("customers/readCustomersByState", async ({ indianStateCode }, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/serviceapi/readCustomersByState?state=${indianStateCode}`);
    const dropdownOptions = data.map((customer) => ({
      label: customer.name,
      value: customer.id.toString(),
    }));

    return { customers: data, indianStateCode, dropdownOptions };
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const readAllCustomersByState = createAsyncThunk(
  "customers/readAllCustomersByState",
  async ({ indianStateCode }, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/readAllCustomersByState?state=${indianStateCode}`);

      const dropdownOptions = data.map((customer) => ({
        label: customer.name,
        value: customer.id.toString(),
      }));

      return { customers: data, indianStateCode, dropdownOptions };
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createLead = createAsyncThunk("customers/createLead", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/createLead", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateLead = createAsyncThunk("customers/updateLead", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/updateLead", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteLead = createAsyncThunk("customers/deleteLead", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/deleteLead", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const readMachinesByCustomerId = createAsyncThunk("customers/readMachinesByCustomerId", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post("/serviceapi/readMachinesByCustomerId?id=" + payload);
    if (data) return data.sort((a, b) => b.id - a.id);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const readCustomerById = createAsyncThunk("customers/readCustomerById", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post("/salesapi/readCustomerById?id=" + payload);
    if (data && data.contacts) data.contacts = data.contacts.sort((a, b) => b.id - a.id);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteMachineFromCustomer = createAsyncThunk("customers/deleteMachineFromCustomer", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/deleteMachineFromCustomer?id=" + data.id, data);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const addContactToLead = createAsyncThunk("customers/addContactToLead", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/addContactToLead", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateContact = createAsyncThunk("customers/updateContact", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/updateContact", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getServiceTicketsForCustomer = createAsyncThunk(
  "customers/getServiceTicketsForCustomer",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.get("/serviceapi/getServiceTicketsForCustomer?customerId=" + payload);
      if (response?.data)
        return {
          ...response,
          data: response?.data
            .map((item) => ({
              ...item,
              dateCreated: item.dateCreated ? moment(item.dateCreated).format("DD MMM YYYY") : "N.A",
              closedDate: item.closedDate ? moment(item.closedDate).format("DD MMM YYYY") : "N.A",
              dtCreated: item.dateCreated,
              dtClosed: item.closedDate 
            }))
            .sort((a, b) => b.serviceTicketId - a.serviceTicketId),
        };
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteContactById = createAsyncThunk("customers/deleteContactById", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/deleteContactById?id=" + data.id, data);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const addMachineToCustomers = createAsyncThunk("customers/addMachineToCustomers ", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/salesapi/addMachineToCustomers ", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
