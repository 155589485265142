import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography, Autocomplete, Container } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getAppUsers } from "../../../features/users/actions";
import { createServiceTicket, readServiceTicket, updateServiceTicket } from "../../../features/servicetickets/actions";
import { readMachinesByCustomerId, readCustomersByState } from "../../../features/customers/actions";
import { getCustomersWithServiceTickets } from "../../../features/servicetickets/actions";
import { readAsyncStorageValues, getStatesWithServiceTickets, getStatesWithCustomers } from "../../../features/common/actions";

const CreateServiceTicket = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const asyncStorageValues = useSelector((state) => state.common.asyncStorageValues);
  const { serviceTicketById } = useSelector((state) => state.serviceTickets);
  const { appUsersList } = useSelector((state) => state.users);

  const { userCurrentRole } = useSelector((state) => state.auth);
  const { loading: customersLoading, customersByState, machinesByCustomerId } = useSelector((state) => state.customers);
  const {
    loading: commonLoading,
    indianStateDropdownOptions,
    statesWithServiceTickets,
    statesWithCustomers,
  } = useSelector((state) => state.common);
  const [customerDropdownOptions, setCustomerDropdownOptions] = useState([]);
  const [indianStates, setIndianStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [salesUsersList, setSalesUsersList] = useState([]);
  const [serviceEngineerList, setServiceEngineerList] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [selectedSalesperson, setSelectedSalesperson] = useState({ name: "", userId: null });
  const [selectedServiceEngineers, setSelectedServiceEngineers] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [description, setDescription] = useState("");
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    state: false,
    customer: false,
    category: false,
    salesPerson: false,
    serviceEngineers: false,
    machine: false,
    scheduledDate: false,
    description: false,
  });

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAppUsers());
    dispatch(getStatesWithCustomers());
    if (id) dispatch(readServiceTicket(id));
  }, [dispatch, id]);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(readCustomersByState({ indianStateCode: selectedState ? selectedState.value : "" }));
    };
    fetch();
    setLoading(false);
  }, [dispatch, selectedState]);

  useEffect(() => {
    if (statesWithCustomers.data) setIndianStates(statesWithCustomers.data);
  }, [statesWithCustomers]);

  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
  }, [dispatch]);

  useEffect(() => {
    if (customersByState && Array.isArray(customersByState.customers)) {
      const dropdownOptions = customersByState.customers.map((customer) => ({
        label: customer.name,
        value: customer.id.toString(),
      }));
      setCustomerDropdownOptions(dropdownOptions);
    } else {
      console.error("customersByState", customersByState.customers);
    }
  }, [customersByState]);

  useEffect(() => {
    if (asyncStorageValues?.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const svcCord = appUsersList.data
        .filter((obj) => obj.userRole === roles.ROLE_SERVICE_COORDINATOR && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));
      const salesUsers = appUsersList.data
        .filter((obj) => (obj.userRole === roles.ROLE_REGIONAL_SALES_MANAGER || obj.userRole === roles.ROLE_SALES_PERSON) && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_REGIONAL_SALES_MANAGER) {
        setSalesUsersList(salesUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setSalesUsersList(salesUsers);
      }

      const serviceUsers = appUsersList.data
        .filter(
          (obj) =>
            (obj.userRole === roles.ROLE_SERVICE_ENGINEER && obj.enabled) ||
            (obj.userRole === roles.ROLE_SERVICE_COORDINATOR && obj.enabled)
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_SERVICE_ENGINEER) {
        setServiceEngineerList(serviceUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setServiceEngineerList(serviceUsers);
      }
    }
  }, [appUsersList, asyncStorageValues, userCurrentRole]);

  useEffect(() => {
    if (asyncStorageValues?.allTypes?.SERVICE_CATEGORY) {
      const categories = Object.values(asyncStorageValues.allTypes.SERVICE_CATEGORY);
      setServiceCategories(categories);
    }
  }, [asyncStorageValues]);
  useEffect(() => {
    if (selectedCustomer) {
      const customerId = selectedCustomer.value;
      dispatch(readMachinesByCustomerId(customerId));
    } else {
      setFilteredMachines([]);
    }
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    if (machinesByCustomerId.data) {
      const formattedMachines =
        machinesByCustomerId?.data?.map((machine) => ({
          ...machine,
          label: formatMachineLabel(machine),
        })) || [];
      setFilteredMachines(formattedMachines);
    } else {
      setFilteredMachines([]);
    }
  }, [machinesByCustomerId]);

  useEffect(() => {
    if (id && serviceTicketById) {
      setSelectedState(indianStateDropdownOptions.find((option) => option.value === serviceTicketById.stateCode));
      setSelectedCustomer({ label: serviceTicketById.customerName, value: serviceTicketById.customerId });
      setSelectedMachine({ label: formatMachineLabel(serviceTicketById.machine), id: serviceTicketById.machineId });
      setSelectedCategory(serviceTicketById.ticketCategory);
      setSelectedSalesperson({ name: serviceTicketById.salesPersonName, userId: serviceTicketById.salesPersonId });
      setSelectedServiceEngineers(serviceTicketById.serviceEngineers.map((engineer) => ({ name: engineer.name, userId: engineer.userId })));
      setScheduledDate(moment(serviceTicketById.scheduledDate));
      setDescription(serviceTicketById.description);
    }
  }, [id, serviceTicketById, indianStateDropdownOptions]);

  const formatMachineLabel = (machine) => {
    const { manufacturer, model, serialNumber, amcExpiryDate, warrantyExpiryDate } = machine;
    let serviceStatus;

    if (warrantyExpiryDate && new Date(warrantyExpiryDate) > new Date()) {
      serviceStatus = `Warranty ${moment(warrantyExpiryDate).format("DD MMM YY")}`;
    } else if (amcExpiryDate && new Date(amcExpiryDate) > new Date()) {
      serviceStatus = `AMC ${moment(amcExpiryDate).format("DD MMM YY")}`;
    } else {
      serviceStatus = "PAID SERVICE";
    }

    return `${manufacturer}, ${model} [${serialNumber}, ${serviceStatus}]`;
  };

  const handleStateChange = (event, newValue) => {
    setSelectedState(newValue);
    setSelectedCustomer(null);
    setSelectedMachine(null);
    setFilteredMachines([]);
    setSelectedCategory(null);
    setScheduledDate(null);
    setSelectedSalesperson(null);
    setSelectedServiceEngineers([]);
    setDescription([]);
    if (newValue) {
      dispatch(readCustomersByState({ indianStateCode: newValue.value }));
    }
  };
  const onSalesPersonChange = (event, newValue) => setSelectedSalesperson(newValue);
  const onServiceEngineersChange = (event, newValue) => setSelectedServiceEngineers(newValue);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = {
      state: !selectedState,
      customer: !selectedCustomer,
      category: !selectedCategory,
      salesPerson: !selectedSalesperson,
      serviceEngineers: selectedServiceEngineers.length === 0,
      machine: !selectedMachine,
      scheduledDate: !scheduledDate,
      description: !description,
    };

    setErrors(validationErrors);

    const hasErrors = Object.values(validationErrors).some((error) => error);
    if (hasErrors) {
      toast.error("Please fill out all required fields.");
      return;
    }
    setIsSubmitting(true);
    const scheduledOn = scheduledDate ? moment(scheduledDate).format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
    const scheduledDateFormatted = scheduledDate ? moment(scheduledDate).format("DD MMM YY") : null;
    let serviceTicketPaymentType = "Paid Service";
    if (selectedMachine?.warrantyExpiryDate && new Date(selectedMachine.warrantyExpiryDate) > new Date()) {
      serviceTicketPaymentType = "Warranty";
    } else if (selectedMachine?.amcExpiryDate && new Date(selectedMachine.amcExpiryDate) > new Date()) {
      serviceTicketPaymentType = "AMC";
    }
    const { ASSIGNED, ASSIGN_SERVICE_ENGINEER } = asyncStorageValues.allTypes.SERVICE_TICKET_STATUS;
    const data = {
      ticketCategory: selectedCategory,
      machineId: selectedMachine?.id,
      customerId: selectedCustomer?.value,
      salesPersonId: selectedSalesperson?.userId,
      otherUsers: [],
      description,
      assignees: selectedServiceEngineers.map((engineer) => engineer.userId),
      serviceEngineers: selectedServiceEngineers.map((engineer) => engineer.name),
      status: selectedServiceEngineers.length ? ASSIGNED : ASSIGN_SERVICE_ENGINEER,
      scheduledDate: scheduledOn,
      scheduledDateFormatted,
      serviceTicketPaymentType,
      paidTicketOverride: false,
      customerName: selectedCustomer?.label,
      machineName: selectedMachine?.label,
      salesPersonName: selectedSalesperson?.name,
    };
    try {
      if (id) {
        await dispatch(updateServiceTicket(id, data));
        toast.success(`Service ticket updated`, { autoClose: 1000 });
        navigate(-1);
      } else {
        await dispatch(createServiceTicket(data));
        toast.success(`Service ticket created`, { autoClose: 1000 });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An error occurred while creating/updating the ticket.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight={600} component="h2" sx={{ mb: 2, textAlign: "center", marginTop: 3 }}>
          {id ? "Edit Service Ticket" : "Create Service Ticket"}
        </Typography>
        <Grid container spacing={2} sx={{ marginRight: "4%", marginLeft: "4%" }}>
          <Grid item xs={10}>
            <Autocomplete
              options={indianStates}
              getOptionLabel={(option) => option.label}
              value={selectedState}
              onChange={handleStateChange}
              renderInput={(params) => <TextField {...params} label="Select State*" />}
              error={errors.state}
              helperText={errors.state ? "State is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={customerDropdownOptions}
              getOptionLabel={(option) => option.label}
              value={selectedCustomer}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
                setSelectedMachine(null);
                setFilteredMachines([]);
              }}
              renderInput={(params) => <TextField {...params} label="Select Customer*" />}
              required
              error={errors.customer}
              helperText={errors.customer ? "Customer is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={filteredMachines}
              getOptionLabel={(option) => option.label}
              value={selectedMachine}
              onChange={(event, newValue) => setSelectedMachine(newValue)}
              renderInput={(params) => <TextField {...params} label="Select Machine*" />}
              required
              error={errors.machine}
              helperText={errors.machine ? "Machine is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={serviceCategories}
              getOptionLabel={(option) => option}
              value={selectedCategory}
              onChange={(event, newValue) => setSelectedCategory(newValue)}
              renderInput={(params) => <TextField {...params} label="Select Category*" />}
              required
              error={errors.category}
              helperText={errors.category ? "Category is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={salesUsersList}
              getOptionLabel={(option) => option.name}
              value={selectedSalesperson}
              onChange={onSalesPersonChange}
              renderInput={(params) => <TextField {...params} label="Select Salesperson*" />}
              required
              error={errors.salesPerson}
              helperText={errors.salesPerson ? "Salesperson is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              multiple
              options={serviceEngineerList}
              getOptionLabel={(option) => option.name}
              value={selectedServiceEngineers}
              onChange={onServiceEngineersChange}
              renderInput={(params) => <TextField {...params} label="Select Service Engineers*" />}
              required
              error={errors.serviceEngineers}
              helperText={errors.serviceEngineers ? "At least one service engineer is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Scheduled Date*"
                value={scheduledDate}
                onChange={(newValue) => setScheduledDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disablePast
                required
                format={"DD MMM YYYY"}
                error={errors.scheduledDate}
                helperText={errors.scheduledDate ? "Scheduled date is required" : ""}
                clearOnEscape
                clearOnBlur
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              label="Description"
              multiline
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
              error={errors.description}
              helperText={errors.description ? "Description is required" : ""}
              clearOnEscape
              clearOnBlur
            />
          </Grid>
          <Grid item xs={10}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginLeft: 50, textTransform: "capitalize" }}
              disabled={isSubmitting}
            >
              {id ? "Update Ticket" : "Create Ticket"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateServiceTicket;
