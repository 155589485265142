import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Bar, XAxis, YAxis, CartesianGrid, Legend, ComposedChart } from "recharts";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Grid, Tooltip, Button, Stack, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { getServiceTicketsForCustomer } from "../../features/customers/actions";
import { exportToCsv } from "../../utils/utilFunctions";
import { getCustomersWithServiceTickets } from "../../features/servicetickets/actions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getTotalSVCHoursByCustomer, getServiceTicketRating } from "../../features/dashboard/actions";
import ServiceHoursByTicketCategoryForCustomer from "../../components/ServiceHoursByTicketCategoryForCustomer";
import ServiceTicketRatingForCustomer from "../../components/ServiceTicketRatingForCustomer";

const AllServiceTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceTicketsForCustomer } = useSelector((state) => state.customers);
  const { customersWithServiceTickets } = useSelector((state) => state.serviceTickets);
  const { serviceHoursByTicketCategoryForCustomer, loading: serviceHoursLoading } = useSelector((state) => state.dashboard);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { serviceTicketRatingForCustomer } = useSelector((state) => state.dashboard);
  const [filters, setFilters] = useState({ engineer: "", category: "", status: "" });

  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
  }, [dispatch]);

  useEffect(() => {
    if (customersWithServiceTickets.data.length) {
      setSelectedCustomer(customersWithServiceTickets.data[0]);
    }
  }, [customersWithServiceTickets]);
  useEffect(() => {
    if (selectedCustomer?.customerId) {
      dispatch(getServiceTicketRating({ customerId: selectedCustomer.customerId }));
    }
  }, [dispatch, selectedCustomer]);

  const FilterDropdown = ({ label, value, options, onChange }) => (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      displayEmpty
      style={{ width: "100%", fontSize: "0.875rem", fontWeight: value ? "normal" : 500 }}
      renderValue={(selected) => selected || <span style={{ fontWeight: 500 }}>{label}</span>}
    >
      <MenuItem value="" style={{ fontWeight: 500 }}>{label}</MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );

  const { engineers, categories, statuses } = useMemo(() => {
    const engineers = new Set();
    const categories = new Set();
    const statuses = new Set();

    serviceTicketsForCustomer?.data?.forEach((ticket) => {
      if (ticket["Service Engineer"]) engineers.add(ticket["Service Engineer"]);
      categories.add(ticket.ticketCategory);
      statuses.add(ticket.status);
    });

    return {
      engineers: Array.from(engineers),
      categories: Array.from(categories),
      statuses: Array.from(statuses),
    };
  }, [serviceTicketsForCustomer]);

  const filteredTickets = useMemo(() => {
    let tickets = serviceTicketsForCustomer?.data || [];
    if (filters.engineer) tickets = tickets.filter((t) => t["Service Engineer"] === filters.engineer);
    if (filters.category) tickets = tickets.filter((t) => t.ticketCategory === filters.category);
    if (filters.status) tickets = tickets.filter((t) => t.status === filters.status);
    return tickets;
  }, [serviceTicketsForCustomer, filters]);


  const getTimeTaken = (row) => {
    const { dtCreated, dtClosed } = row;
  
    if (dtCreated && dtClosed) {
      const createdDate = moment(dtCreated);
      const closedDate = moment(dtClosed);
  
      const diffDays = closedDate.diff(createdDate, "days");
      const diffHours = closedDate.diff(createdDate, "hours") % 24;
      const diffMinutes = closedDate.diff(createdDate, "minutes") % 60;
  
      if (diffDays > 0) {
        return `${diffDays} day${diffDays !== 1 ? "s" : ""}`;
      } else if (diffHours > 0) {
        return `${diffHours} hour${diffHours !== 1 ? "s" : ""}`;
      } else if (diffMinutes > 0) {
        return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""}`;
      }
    }
    return "N.A";
  };
  
  useEffect(() => {
    const fetchCustomerServiceHours = async () => {
      try {
        if (selectedCustomer?.customerId) {
          await dispatch(getTotalSVCHoursByCustomer({ customerId: selectedCustomer.customerId }));
        } else {
          console.error("customerId is undefined");
        }
      } catch (error) {
        console.error("Error fetching service hours:", error);
      } finally {
        setLoading(false);
      }
    };
    if (selectedCustomer) {
      setLoading(true);
      fetchCustomerServiceHours();
    }
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    const fetchCustomerServiceTickets = async () => {
      try {
        await dispatch(getServiceTicketsForCustomer(selectedCustomer.customerId));
      } catch (error) {
        console.error("Error fetching service tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCustomer) {
      setLoading(true);
      fetchCustomerServiceTickets();
    }
  }, [selectedCustomer]);

  const onCustomerChange = (event, newValue) => {
     setSelectedCustomer(newValue);
     setFilters({ engineer: "", category: "", status: "" });
  };

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="View">
          <Button
            startIcon={<ZoomInIcon />}
            color="primary"
            size="large"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/all-service-ticket-view/" + params.row.serviceTicketId);
            }}
          />
        </Tooltip>
        {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER &&
          asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
            <Tooltip title="Edit">
              <Button
                startIcon={<EditIcon />}
                color="primary"
                size="large"
                style={{ marginLeft: -12, fontWeight: "bold" }}
                onClick={() => {
                  navigate("/all-service-ticket-view/" + params.row.serviceTicketId, { state: { editMode: true } });
                }}
              />
            </Tooltip>
          )}
      </strong>
    );
  };

  const columns = [
    { field: "serviceTicketId", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.2,
      sortComparator: dateComparator,
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.2,
      sortComparator: (v1, v2) => {
        if (v1 === "N.A" && v2 === "N.A") return 0;
        if (v1 === "N.A") return -1;
        if (v2 === "N.A") return 1;
        return moment(v1).diff(moment(v2));
      },
    },    
    {
      field: "daysTaken",
      headerName: "Time Taken",
      flex: 0.2,
      valueGetter: ({ row }) => {
        const { dtCreated, dtClosed } = row;
    
        if (dtCreated && dtClosed) {
          const createdDate = moment(dtCreated);
          const closedDate = moment(dtClosed);
    
          return closedDate.diff(createdDate, "minutes");
        }
        return null;
      },
      sortComparator: (v1, v2) => {
        if (v1 === null || v2 === null) return v1 === null ? -1 : 1;
        return v1 - v2;
      },
      valueFormatter: ({ value }) => {
        if (value === null) return "N.A";
        const days = Math.floor(value / (24 * 60));
        const hours = Math.floor((value % (24 * 60)) / 60);
        const minutes = value % 60;
    
        if (days > 0) {
          return `${days} day${days !== 1 ? "s" : ""}`;
        } else if (hours > 0) {
          return `${hours} hour${hours !== 1 ? "s" : ""}`;
        } else if (minutes > 0) {
          return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
        }
        return "N.A";
      },
    },    
    { field: "assigner", headerName: "Created By", width: 250 },
    {
      field: "Service Engineer",
      headerName: "Service Engineer",
      width: 250,
      renderHeader: () => (
        <FilterDropdown
          label="Service Engineer"
          value={filters.engineer}
          options={engineers}
          onChange={(value) => setFilters({ category: "", status: "" , engineer: value })}
        />
      ),
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      width: 250,
      renderHeader: () => (
        <FilterDropdown
          label="Category"
          value={filters.category}
          options={categories}
          onChange={(value) => setFilters({ engineer: "", status: "" , category: value })}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderHeader: () => (
        <FilterDropdown
          label="Status"
          value={filters.status}
          options={statuses}
          onChange={(value) => setFilters({ engineer: "", category: "", status: value })}  
        />
      ),
    },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Service Ticket Id, Date Created, Date Closed, No. of Days Taken, Service Engineer,Ticket Category, Status"];
    const keys = ["serviceTicketId", "dateCreated", "closedDate", "daysTaken", "Service Engineer", "ticketCategory", "status"];
    exportToCsv(headers, keys, serviceTicketsForCustomer.data, "Service_Tickets");
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mx={3} my={2}>
          {customersWithServiceTickets.loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Autocomplete
              disablePortal
              id="customerdropdown"
              options={customersWithServiceTickets.data.slice()}
              onChange={onCustomerChange}
              value={selectedCustomer}
              getOptionLabel={(option) => option.customerName}
              renderInput={(params) => <TextField {...params} label="Customer Name" sx={{ width: 660, marginTop: "10px" }} />}
            />
          )}

          {/* <Button
            variant="contained"
            sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
            onClick={downloadCsv}
            disabled={serviceTicketsForCustomer.data.length ? false : true}
          >
            Export to CSV
          </Button> */}
        </Stack>

        <Stack mt={3} spacing={1}>
          <Stack direction="row" ml={3} mr={3} gap={3} flex={1}>
            <Box flex={1}>
              {selectedCustomer && serviceHoursByTicketCategoryForCustomer?.data?.length ? (
                <ServiceHoursByTicketCategoryForCustomer selectedCustomer={selectedCustomer} />
              ) : (
                serviceHoursLoading && <CircularProgress color="secondary" />
              )}
            </Box>
            <Box flex={1}>
              {selectedCustomer ? (
                <ServiceTicketRatingForCustomer selectedCustomer={selectedCustomer} />
              ) : (
                serviceHoursLoading && <CircularProgress color="secondary" />
              )}
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ height: 700, px: 3, mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
          {loading || serviceTicketsForCustomer.loading ? (
            <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
          ) : (
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGrid
                rows={selectedCustomer ? filteredTickets : []}
                getRowId={(row) => row.serviceTicketId}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                localeText={{ noRowsLabel: "No tickets." }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AllServiceTickets;
