import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, NavLink, useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { logout } from "../../features/auth/slice";
import "./styles.css";
import customerLogo from "../../app/assets/images/app_logo.png";

const Header = () => {
  const { userInfo, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <header>
      <div className="header-status">
        <div className="header-logo">
          <Box
            sx={{ width: 200, resizeMode: "contain", marginLeft: -2, marginBottom: -2, marginTop: -3 }}
            component="img"
            src={customerLogo}
            alt="logo"
          />
        </div>
        <div className="cta">
          <span>
            {loading ? `Fetching your profile...` : userInfo !== null ? `Logged in as ${userInfo.username}` : "You're not logged in"}
          </span>
          {userInfo ? (
            <Button
              variant="contained"
              onClick={() => {
                dispatch(logout());
                navigate("/login");
              }}
              sx={{ backgroundColor: "#DAA520" }}
            >
              Logout
            </Button>
          ) : (
            <Button component={RouterLink} variant="contained" to="/login" sx={{ backgroundColor: "#DAA520" }}>
              Login
            </Button>
          )}
        </div>
      </div>
      {/* <nav className="container navigation">
        <Link component={NavLink} to="/" variant="h6">
          Last 4 years
        </Link>
        <Link component={NavLink} to="/dashboard/last-12-months" variant="h6">
          Last 12 months
        </Link>
        {!userInfo ? (
          <Link component={NavLink} to="/login" variant="h6">
            Login
          </Link>
        ) : null}
        {userInfo ? (
          <Link component={NavLink} to="/customers" variant="h6">
            Customers
          </Link>
        ) : null}
        {userInfo ? (
          <Link component={NavLink} to="/users" variant="h6">
            Users
          </Link>
        ) : null}
        {userInfo ? (
          <Link component={NavLink} to="/profile" variant="h6">
            Profile
          </Link>
        ) : null}
      </nav> */}
    </header>
  );
};

export default Header;
